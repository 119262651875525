<nav class="mkk-nav">
  <div class="mkk-nav__header">
    <mkk-icon-button
      variant="not-outlined"
      iconName="mkk-cross"
      aria-label="Close overlay"
      (click)="clickClose.emit()"
    ></mkk-icon-button>
  </div>
  <div class="mkk-nav__user-area">
    @if (userData && isLoggedIn()) {
      <div class="logged-in">
        <div class="user">
          <div class="initials">
            <span class="no-text-space">{{ userData | initials }}</span>
          </div>
          <div>
            <div class="fullname">{{ userData.givenName }} {{ userData.familyName }}</div>
            <div class="insurance-number">{{ userData.kvnr }}</div>
          </div>
        </div>
        <div class="personal-area">
          <span class="nav-section-headline">{{ 'ui.misc.nav.loggedIn.personalArea' | translate }}</span>
          <ul class="nav-items">
            <li class="nav-item">
              <a
                routerLink="mein-profil"
                routerLinkActive="active"
                (click)="clickClose.emit()"
              >
                <span class="label">
                  <mkk-icon
                    size="18px"
                    svgIconName="mkk-single-person"
                  />
                  {{ 'ui.misc.nav.loggedIn.myArea' | translate }}
                </span>
                <mkk-icon
                  size="16px"
                  svgIconName="mkk-chevron-right"
                />
              </a>
            </li>
            <li class="nav-item">
              <a (click)="clickSignOut.emit()">
                <span class="label">
                  <mkk-icon
                    size="18px"
                    svgIconName="mkk-unlock"
                  />
                  {{ 'ui.misc.nav.loggedIn.signOut' | translate }}
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    } @else {
      <div class="login">
        <mkk-button
          variant="primary"
          [buttonText]="'ui.misc.header.nav.signIn' | translate"
          (click)="clickSignIn.emit()"
          aria-label="Anmelden"
          fullWidth
        ></mkk-button>
        <mkk-button
          variant="secondary"
          [buttonText]="'ui.misc.header.nav.register' | translate"
          aria-label="Registrieren"
          routerLink="registrieren"
          (click)="clickClose.emit()"
          fullWidth
        ></mkk-button>
      </div>
    }
  </div>
  <div
    class="nav-section-headline"
    [class.hidden]="!isLoggedIn()"
  >
    {{ 'ui.misc.nav.loggedIn.menu' | translate }}
  </div>
  <ul class="nav-items">
    @for (navItemSection of navItems; track navItemSection) {
      @for (navItem of navItemSection; track navItem) {
        <li
          class="nav-item"
          [class.external-link]="isExternalLink(navItem.href)"
        >
          <a
            [target]="getLinkTarget(navItem.href)"
            [routerLink]="isExternalLink(navItem.href) ? undefined : navItem.href"
            routerLinkActive="active"
            (click)="routeToExt(navItem)"
            [attr.aria-label]="'Navigation zu ' + (navItem.label | translate)"
          >
            {{ navItem.label | translate }}
            <mkk-icon
              size="16px"
              [svgIconName]="getLinkIcon(navItem.href)"
              aria-hidden="true"
            />
          </a>
        </li>
      }
      <li class="separator"></li>
    }
  </ul>
</nav>
