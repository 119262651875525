import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslationKeys, TranslationPipe } from '@mkk/translate';
import { RouterLink } from '@angular/router';
import { IconComponent } from '../icon/icon.component';

export type BreadCrumb = {
  label: string | TranslationKeys;
  url: string;
};

@Component({
  selector: 'mkk-breadcrumb',
  standalone: true,
  imports: [CommonModule, TranslationPipe, RouterLink, IconComponent],
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.scss',
})
export class BreadcrumbComponent {
  @Input()
  public breadcrumbs!: BreadCrumb[];
}
