<mkk-loading
  [loading]="(layoutState.loadingState | async)?.loading ?? false"
  [loadingText]="(layoutState.loadingState | async)?.loadingText ?? ''"
>
  @if (upcomingMaintenance$.getValue() && showMaintenanceBanner) {
    <mkk-banner
      [bannerText]="
        'ui.misc.maintenanceBanner.maintenanceText'
          | translate
            : {
                weekDay: upcomingMaintenance$.getValue()?.weekDay,
                fromDate: upcomingMaintenance$.getValue()?.fromDate,
                fromTime: upcomingMaintenance$.getValue()?.fromTime,
                untilTime: upcomingMaintenance$.getValue()?.untilTime,
              }
      "
      (closeBanner)="closeMaintenanceBanner()"
    />
  }

  <!-- TODO: Remove, when Release Banner should not be shown again -->
  @if (showReleaseBanner && !isSecuredRoute()) {
    <mkk-banner
      [bannerText]="'ui.misc.releaseBannerText' | translate"
      (closeBanner)="closeReleaseBanner()"
    />
  }

  @switch (routeData.layout) {
    @case ('full') {
      <mkk-fullscreen-layout [color]="routeData.layoutAccentColor">
        <mkk-header-navigation
          navigation
          [isLoggedIn]="loggedIn$ | async"
          [waitingFor2FAConfirmation]="waitingFor2FAConfirmation()"
          [hideNav]="isOneTimeLoggedIn$ | async"
          (clickSignOut)="signOut()"
          (clickNavigation)="toggleNav()"
          (clickSignIn)="signIn()"
        />
        <mkk-header-with-auth
          header
          [headline]="routeData.header.headline"
          [illustration]="routeData.header.illustration"
          [headerVariant]="getHeaderVariant()"
          [headerContentVariant]="routeData.header.contentVariant"
          (clickNavigation)="toggleNav()"
          onlyIllustration
        ></mkk-header-with-auth>
        @if (breadcrumbItems.length > 1 && (isOneTimeLoggedIn$ | async) === false && !routeData.hideBreadcrumb) {
          <div
            class="center"
            breadcrumb
          >
            <mkk-breadcrumb
              class="half-screen"
              [breadcrumbs]="breadcrumbItems"
            />
          </div>
        }
        <div router>
          <ng-content select="[fullscreen]"></ng-content>
        </div>
        <mkk-footer
          footer
          class="footer"
          [items]="footerData"
        />
      </mkk-fullscreen-layout>
    }
    @case ('online-be') {
      <mkk-online-be-layout>
        <mkk-header-navigation
          navigation
          [isLoggedIn]="loggedIn$ | async"
          [waitingFor2FAConfirmation]="waitingFor2FAConfirmation()"
          [hideNav]="isOneTimeLoggedIn$ | async"
          (clickSignOut)="signOut()"
          (clickNavigation)="toggleNav()"
          (clickSignIn)="signIn()"
        />
        @if (breadcrumbItems.length > 1 && (isOneTimeLoggedIn$ | async) === false) {
          <div
            class="center"
            breadcrumb
          >
            <mkk-breadcrumb
              class="half-screen"
              [breadcrumbs]="breadcrumbItems"
            />
          </div>
        }
        <div router>
          <ng-content select="[onbeit]"></ng-content>
        </div>
        <mkk-footer
          footer
          class="footer"
          [items]="footerData"
        />
      </mkk-online-be-layout>
    }
    @case ('maintenance') {
      <mkk-maintenance-layout>
        <mkk-header-navigation
          navigation
          [isLoggedIn]="loggedIn$ | async"
          [waitingFor2FAConfirmation]="waitingFor2FAConfirmation()"
          (clickSignOut)="signOut()"
          (clickNavigation)="toggleNav()"
          (clickSignIn)="signIn()"
          aria-label="Hauptnavigation"
        />

        <div
          content
          class="mkk-maintenance-layout__content"
        >
          <ng-content select="[maintenance]"></ng-content>
        </div>

        <mkk-footer
          footer
          class="footer"
          [items]="footerData"
        />
      </mkk-maintenance-layout>
    }
    @case (['service', 'landing-page', 'register-header'].includes(routeData.layout) && routeData.layout) {
      <mkk-header-content-layout
        [attr.inert]="showNavigation() ? true : null"
        [contentWidth]="routeData.contentWidth"
        [color]="routeData.layoutAccentColor"
      >
        <mkk-header-navigation
          navigation
          [isLoggedIn]="loggedIn$ | async"
          [waitingFor2FAConfirmation]="waitingFor2FAConfirmation()"
          [hideNav]="isOneTimeLoggedIn$ | async"
          (clickSignOut)="signOut()"
          (clickNavigation)="toggleNav()"
          (clickSignIn)="signIn()"
        />
        <mkk-header-with-auth
          header
          [headline]="routeData.header.headline"
          [label]="routeData.header.label"
          [headerVariant]="getHeaderVariant()"
          [headerContentVariant]="routeData.header.contentVariant"
          [illustration]="routeData.header.illustration"
          (clickNavigation)="toggleNav()"
        ></mkk-header-with-auth>
        @if (breadcrumbItems.length > 1 && (isOneTimeLoggedIn$ | async) === false) {
          <mkk-breadcrumb
            breadcrumb
            [breadcrumbs]="breadcrumbItems"
          />
        }
        <div router>
          <ng-content select="[header-content]"></ng-content>
        </div>
        <mkk-footer
          footer
          class="footer"
          [items]="footerData"
        />
      </mkk-header-content-layout>
    }
    @default {
      <div class="full-centered">
        <mkk-loading />
      </div>
    }
  }

  @if (showDebugNavigation()) {
    <nav
      class="navigation"
      [class.collapsed]="showDebugNavigation()"
    >
      <mkk-icon-button
        variant="not-outlined"
        iconName="mkk-cross"
      />
      <div
        class="backdrop"
        aria-label="Close navigation"
        (click)="toggleDebugNav(false)"
      ></div>
      <div class="nav-items">
        <button
          *ngFor="let debugRoute of debugRoutes"
          [routerLink]="debugRoute.route.path"
          [class.active]="router.url.includes(debugRoute.route.path ?? '')"
          [disabled]="(loggedIn$ | async) === false && debugRoute.isSecured"
          (click)="toggleDebugNav(false)"
          [attr.aria-label]="'Navigation zu ' + debugRoute.route.title?.toString()"
        >
          {{ debugRoute.route.data?.['titleKey'] | translate }}
        </button>
        <button (click)="debug_simulate2faClosed(); toggleDebugNav(false)">2FA-Confirm Close Simulation</button>
        <button
          [disabled]="(loggedIn$ | async) === false"
          (click)="debug_simulateSessionTimeout(); toggleDebugNav(false)"
        >
          Session timeout Simulation
        </button>
        <button
          [disabled]="(loggedIn$ | async) === false"
          (click)="debug_simulateSessionClosed(); toggleDebugNav(false)"
        >
          Session close Simulation
        </button>
      </div>
    </nav>
  }
  <div
    class="mkk-backdrop"
    [class.visible]="showNavigation()"
    (click)="toggleNav(false)"
  ></div>
  <mkk-navigation
    aria-label="Mobilnavigation"
    [attr.inert]="!showNavigation() ? true : null"
    class="mkk-navigation"
    [class.hidden]="(loggedIn$ | async) === false"
    [class.expanded]="showNavigation()"
    [class.collapsed]="!showNavigation()"
    [attr.aria-expanded]="showNavigation()"
    [attr.aria-hidden]="!showNavigation()"
    [navItems]="getNavItems()"
    (clickSignIn)="signIn()"
    (clickSignOut)="signOut()"
    (clickClose)="toggleNav(false)"
    [userData]="userData$ | async"
  />
</mkk-loading>
