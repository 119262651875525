import { WrongIssuerLogoutGuard } from '@mkk/auth';
import { MkkRoute, services } from '@mkk/meine-kkh/domain';
import { categoryOverviewRoutes } from './category-routes/category-overview.routes';
import { certificatesRoutes } from './category-routes/certificates.routes';
import { healthGuidesRoutes } from './category-routes/health-guides.routes';
import { kkhAdvantagesRoutes } from './category-routes/kkhAdvantages.routes';
import { myAreaRoutes } from './category-routes/myArea.routes';
import { restRoutes } from './category-routes/rest.routes';
import { sickDaysRoutes } from './category-routes/sickDays.routes';
import { stayHealthyRoutes } from './category-routes/stay-healthy.routes';

export const appRoutes: MkkRoute[] = [
  {
    path: '',
    canActivate: [WrongIssuerLogoutGuard],
    title: 'Meine KKH',
    data: {
      layout: 'landing-page',
      header: {
        headline: 'ui.misc.header.headline',
        label: 'ui.misc.header.news',
        illustration: 'mkk-hero',
        contentVariant: 'speech-bubble',
      },
      contentWidth: 'wide',
    },
    loadComponent: () =>
      import('./landing-page/landing-page.component').then(({ LandingPageComponent }) => LandingPageComponent),
  },
  {
    path: 'registrieren',
    title: 'Registrieren',
    data: {
      layoutAccentColor: '--kkhds-color-palette-light-green-200',
      layout: 'register-header',
      header: {
        headline: 'services.register.headline',
        label: 'services.register.label',
        illustration: 'mkk-register',
      },
      contentWidth: 'wide',
    },
    loadComponent: () =>
      import('./register-page/register-page.component').then(({ RegisterPageComponent }) => RegisterPageComponent),
  },
  {
    path: 'wartung',
    title: 'Wartungsarbeiten',
    data: {
      layout: 'maintenance',
      variant: 'maintenance',
    },
    loadComponent: () =>
      import('./maintenance/maintenance.component').then(({ MaintenanceComponent }) => MaintenanceComponent),
  },
  {
    path: 'service-nicht-erreichbar',
    title: 'Service nicht erreichbar',
    data: {
      layout: 'maintenance',
      variant: 'service',
    },
    loadComponent: () =>
      import('./maintenance/maintenance.component').then(({ MaintenanceComponent }) => MaintenanceComponent),
  },
  {
    path: 'technischer-fehler',
    title: 'Technischer Fehler',
    data: {
      layout: 'maintenance',
      variant: 'technical',
    },
    loadComponent: () =>
      import('./maintenance/maintenance.component').then(({ MaintenanceComponent }) => MaintenanceComponent),
  },
  {
    path: 'datenschutz',
    title: 'Datenschutz',
    data: {
      header: {
        headline: 'Datenschutz',
        illustration: 'mkk-ballon',
      },
    },
    loadComponent: () =>
      import('./dataPrivacy/dataPrivacy.component').then(({ DataPrivacyComponent }) => DataPrivacyComponent),
  },
  {
    path: 'in-bearbeitung',
    title: 'In Bearbeitung',
    data: {
      header: {
        headline: 'Service in Bearbeitung',
        label: 'Der aktuelle Service befindet sich noch in der Entwicklung',
        illustration: 'mkk-technical-error',
      },
    },
    loadComponent: () =>
      import('./under-construction/under-construction.component').then(
        ({ UnderConstructionComponent }) => UnderConstructionComponent,
      ),
  },
  ...categoryOverviewRoutes,
  ...restRoutes,
  ...certificatesRoutes,
  ...healthGuidesRoutes,
  ...kkhAdvantagesRoutes,
  ...myAreaRoutes,
  ...sickDaysRoutes,
  ...stayHealthyRoutes,
  {
    path: '**',
    redirectTo: '',
  },
];

export const securedRoutes = [
  ...restRoutes.filter(({ path }) => path?.includes(services.accidentQuestionnaire.href)),
  ...restRoutes.filter(({ path }) => path?.includes(services.faveBe.href)),
  ...restRoutes.filter(({ path }) => path?.includes(services.lightPictureOtl.href)),
  ...myAreaRoutes.filter(({ path }) => path?.includes(services.sepaEmployer.href)),
].map((route) => {
  const securedArea = route.canActivate?.map((v) => v.name).includes('_IsLoggedInGuard');
  return { route, isSecured: securedArea };
});
