/**
 * KKH elektronische Arbeitsunfähigkeitsbescheinigung
 * This is a server where a user of the KKH online services can view their electronically transmitted sickness reports.
 *
 * The version of the OpenAPI document: #{placeholderVersion}#
 * Contact: online@kkh.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface IcdDataBvs {
  /**
   * the idc code for classification of diseases
   */
  icdCode: string;
  /**
   * Certainty of the diagnosis.   * \'G\' - gesicherte Diagnose   * \'V\' - Verdachtsdiagnose   * \'Z\' - Zustand nach der betreffenden Diagnose   * \'A\' - Ausschlussdiagnose
   */
  diagnosisCertainty?: IcdDataBvs.DiagnosisCertaintyBvs | null;
}
export namespace IcdDataBvs {
  export type DiagnosisCertaintyBvs = 'G' | 'V' | 'Z' | 'A';
  export const DiagnosisCertaintyBvs = {
    G: 'G' as DiagnosisCertaintyBvs,
    V: 'V' as DiagnosisCertaintyBvs,
    Z: 'Z' as DiagnosisCertaintyBvs,
    A: 'A' as DiagnosisCertaintyBvs,
  };
}
