import { ModuleWithProviders, NgModule } from '@angular/core';
import { UpcomingMaintenanceService } from './services/upcoming-maintenance.service';
import { MkkEnvironment } from '@mkk/env';
import { MaintenanceService } from './services/maintenance.service';

@NgModule({
  imports: [],
  declarations: [],
  providers: [],
  exports: [],
})
export class MaintenanceModule {
  public static forRoot(env: MkkEnvironment): ModuleWithProviders<MaintenanceModule> {
    return {
      ngModule: MaintenanceModule,
      providers: [UpcomingMaintenanceService, MaintenanceService],
    };
  }
}
