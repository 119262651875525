import { ModuleWithProviders, NgModule } from '@angular/core';
import { MkkEnvironment } from '@mkk/env';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  HttpXsrfRequestInterceptor,
  HttpXsrfResponseInterceptor,
  HttpXsrfTokenStorage,
} from './xsrf-interceptor/xsrf.interceptor';
import { ORIGIN_URL_WHITELIST } from '@mkk/auth';

@NgModule({
  imports: [],
  declarations: [],
  providers: [],
  exports: [],
})
export class InterceptorsModule {
  public static forRoot(env: MkkEnvironment): ModuleWithProviders<InterceptorsModule> {
    return {
      ngModule: InterceptorsModule,
      providers: [
        {
          provide: ORIGIN_URL_WHITELIST,
          useValue: [env.gatewayOrigin],
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpXsrfRequestInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpXsrfResponseInterceptor,
          multi: true,
        },
        HttpXsrfTokenStorage,
      ],
    };
  }
}
