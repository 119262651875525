<footer class="mkk-footer">
  <div class="items">
    @for (item of items; track item) {
      @if (item.href.startsWith('https://')) {
        <a
          [attr.href]="item.href"
          [attr.target]="item.target"
          class="item"
          >{{ item.label | translate }}</a
        >
      } @else {
        <a
          [routerLink]="item.href"
          class="item"
          >{{ item.label | translate }}</a
        >
      }
    }
  </div>
  <div class="legal">
    <span class="copyright">
      &#169;
      {{ currentYear }}
      {{ ' ' }}
      {{ 'companyName' | translate }}</span
    >
    <span>{{ 'address' | translate }}</span>
    <span>Version: {{ version }}</span>
  </div>
</footer>
