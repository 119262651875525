import { CommonModule } from '@angular/common';
import { Component, HostListener, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ContentWidth, LayoutBackgroundColor, services } from '@mkk/meine-kkh/domain';
import { ENVIRONMENT, SessionService, SessionState } from '@mkk/auth';
import { MkkEnvironment } from '@mkk/env';

@Component({
  selector: 'mkk-header-content-layout',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './header-content-layout.component.html',
  styleUrl: './header-content-layout.component.scss',
})
export class HeaderContentLayoutComponent {
  private currentSessionState!: SessionState;
  private readonly serviceHrefs = Object.values(services)
    .map((service) => service.href)
    .filter((e) => !!e);

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: BeforeUnloadEvent): void {
    if (this.env.name === 'local') return; // deactivate unload event for local development and hotfix issues
    if (this.currentSessionState === 'TIMEOUT' || this.currentSessionState === 'CLOSED') return;
    const urlMatchesHrefOfService = this.serviceHrefs.some((href) => this.router.url.includes(href));
    if (!urlMatchesHrefOfService) return;

    $event.preventDefault();
  }

  constructor(
    private router: Router,
    private sessionService: SessionService,
    @Inject(ENVIRONMENT) readonly env: MkkEnvironment,
  ) {
    this.sessionService.session$.subscribe((state) => {
      this.currentSessionState = state;
    });
  }

  @Input()
  contentWidth: ContentWidth = 'default';

  @Input()
  color: LayoutBackgroundColor = '--kkhds-color-palette-light-blue-200';
}
