import { IsLoggedInGuard } from '@mkk/auth';
import { MkkRoute, services } from '@mkk/meine-kkh/domain';
import { CustomTitleResolver } from '../../resolver/custom-title.resolver';

export const certificatesRoutes: MkkRoute[] = [
  {
    title: CustomTitleResolver,
    data: {
      titleKey: services.docUpload.title,
      header: {
        headline: services.docUpload.title,
        label: services.docUpload.description,
        illustration: services.docUpload.illustration,
      },
      layout: 'full',
    },
    path: 'dokumente-hochladen',
    canActivate: [IsLoggedInGuard],

    loadComponent: () => import('@mkk/dok-upload/component').then(({ DokUploadComponent }) => DokUploadComponent),
    loadChildren: () => import('@mkk/dok-upload/component').then(({ routes }) => routes),
  },
  {
    title: CustomTitleResolver,
    data: {
      titleKey: services.certificate.title,
      header: {
        headline: services.certificate.title,
        label: services.certificate.description,
        illustration: services.certificate.illustration,
      },
      layout: 'full',
    },
    path: 'mitgliedsbescheinigung',
    canActivate: [IsLoggedInGuard],

    loadComponent: () =>
      import('@mkk/mitgliedsbescheinigung/component').then(
        ({ MitgliedsbescheinigungComponent }) => MitgliedsbescheinigungComponent,
      ),
    loadChildren: () => import('@mkk/mitgliedsbescheinigung/component').then(({ routes }) => routes),
  },
  {
    title: CustomTitleResolver,
    data: {
      titleKey: services.fave.title,
      header: {
        headline: services.fave.title,
        label: services.fave.description,
        illustration: services.fave.illustration,
      },
      layout: 'full',
    },
    canActivate: [IsLoggedInGuard],
    path: 'familienversicherung-beantragen',
    loadComponent: () => import('@mkk/fave-antrag/component').then(({ FaveAntragComponent }) => FaveAntragComponent),
    loadChildren: () => import('@mkk/fave-antrag/component').then(({ routes }) => routes),
  },

  {
    title: CustomTitleResolver,
    data: {
      titleKey: services.foreignProtection.title,
      header: {
        headline: services.foreignProtection.title,
        label: services.foreignProtection.description,
        illustration: services.foreignProtection.illustration,
      },
      layout: 'full',
    },
    path: 'auslandsschutz',
    canActivate: [IsLoggedInGuard],

    loadComponent: () =>
      import('@mkk/auslandskrankenschein/component').then(
        ({ AuslandskrankenscheinComponent }) => AuslandskrankenscheinComponent,
      ),
    loadChildren: () => import('@mkk/auslandskrankenschein/component').then(({ routes }) => routes),
  },
];
