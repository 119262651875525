import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbComponent, IconButtonComponent, NavigationComponent } from '@mkk/ui';
import { FooterComponent } from '../../footer/footer.component';
import { HeaderWithAuthComponent } from '@mkk/auth';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'mkk-maintenance-layout',
  standalone: true,
  imports: [
    CommonModule,
    BreadcrumbComponent,
    FooterComponent,
    HeaderWithAuthComponent,
    IconButtonComponent,
    NavigationComponent,
    RouterOutlet,
  ],
  templateUrl: './maintenance-layout.component.html',
  styleUrl: './maintenance-layout.component.scss',
})
export class MaintenanceLayoutComponent {}
