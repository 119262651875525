import { OtlGuard } from '@mkk/auth';
import { MkkRoute, services } from '@mkk/meine-kkh/domain';
import { CustomTitleResolver } from '../../resolver/custom-title.resolver';

export const restRoutes: MkkRoute[] = [
  {
    title: CustomTitleResolver,
    data: {
      titleKey: services.contact.title,
      header: {
        headline: services.contact.title,
        label: services.contact.description,
        illustration: services.contact.illustration,
      },
      layout: 'service',
    },
    path: 'kontaktformular',

    loadComponent: () =>
      import('@mkk/kontaktformular/component').then(({ ContactFormComponent }) => ContactFormComponent),
    loadChildren: () => import('@mkk/kontaktformular/component').then(({ routes }) => routes),
  },
  {
    title: CustomTitleResolver,
    data: {
      titleKey: services.onlineBe.title,
      header: {
        headline: services.onlineBe.title,
        label: services.onlineBe.description,
        illustration: services.onlineBe.illustration,
      },
      layout: 'service',
    },
    path: 'mitglied-werden',
    loadComponent: () => import('@mkk/online-be/component').then(({ LayoutComponent }) => LayoutComponent),
    loadChildren: () => import('@mkk/online-be/component').then(({ routes }) => routes),
  },
  {
    title: CustomTitleResolver,
    data: {
      titleKey: services.faveBe.title,
      header: {
        headline: services.faveBe.title,
        label: services.faveBe.description,
        illustration: services.faveBe.illustration,
      },
      layout: 'full',
    },
    path: 'familienversicherung-fragebogen',
    canActivate: [OtlGuard.forLoginIssuer('favebe')],

    loadComponent: () => import('@mkk/fave-bestand/component').then(({ FaveBestandComponent }) => FaveBestandComponent),
    loadChildren: () => import('@mkk/fave-bestand/component').then(({ routes }) => routes),
  },
  {
    title: CustomTitleResolver,
    data: {
      titleKey: services.lightPicture.title,
      header: {
        headline: services.lightPicture.title,
        label: services.lightPicture.description,
        illustration: services.lightPicture.illustration,
      },
      layout: 'service',
    },
    path: 'lichtbild-hochladen',
    canActivate: [OtlGuard.forLoginIssuer('egkupl')],

    loadComponent: () =>
      import('@mkk/lichtbild-upload/component').then(({ LichtbildUploadComponent }) => LichtbildUploadComponent),
    loadChildren: () => import('@mkk/lichtbild-upload/component').then(({ routes }) => routes),
  },
  {
    title: CustomTitleResolver,
    data: {
      titleKey: services.accidentQuestionnaire.title,
      header: {
        headline: services.accidentQuestionnaire.title,
        label: services.accidentQuestionnaire.description,
        illustration: services.accidentQuestionnaire.illustration,
      },
      layout: 'full',
    },
    path: 'unfallfragebogen',
    canActivate: [OtlGuard.forLoginIssuer('unfall')],

    loadComponent: () =>
      import('@mkk/unfallfragebogen/component').then(({ UnfallfragebogenComponent }) => UnfallfragebogenComponent),
    loadChildren: () => import('@mkk/unfallfragebogen/component').then(({ routes }) => routes),
  },
  {
    path: 'optin-bestaetigung',
    title: 'Opt-in Werbeeinwilligung',
    data: {
      layout: 'full',
      layoutAccentColor: '--kkhds-color-palette-light-green-200',
      hideBreadcrumb: true,
      header: {
        headline: 'optIn.headline',
        illustration: 'mkk-success-thumbs-up',
      },
    },
    loadComponent: () => import('@mkk/opt-in/component').then(({ OptInComponent }) => OptInComponent),
    loadChildren: () => import('@mkk/opt-in/component').then(({ routes }) => routes),
  },

  // Do not delete this comment - Generator Route Placeholder
];
