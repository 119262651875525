import { WrongIssuerLogoutGuard } from '@mkk/auth';
import { MkkRoute, services } from '@mkk/meine-kkh/domain';
import { CustomTitleResolver } from '../../resolver/custom-title.resolver';

export const healthGuidesRoutes: MkkRoute[] = [
  {
    title: CustomTitleResolver,
    data: {
      titleKey: services.himiSu.title,
      header: {
        headline: services.himiSu.title,
        label: services.himiSu.description,
        illustration: services.himiSu.illustration,
      },
      layout: 'full',
      canActivate: [WrongIssuerLogoutGuard],
    },
    path: 'himi-suche',
    loadComponent: () => import('@mkk/himi-suche/component').then(({ HimiSucheComponent }) => HimiSucheComponent),
    loadChildren: () => import('@mkk/himi-suche/component').then(({ routes }) => routes),
  },
];
