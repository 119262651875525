import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { TranslationService } from '@mkk/translate';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CustomTitleResolver implements Resolve<string> {
  constructor(private _translateService: TranslationService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<string> {
    const titleKey = route.data['titleKey'];

    if (!titleKey) {
      return of('Default title');
    }

    return this._translateService.get(titleKey);
  }
}
