import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { catchError, finalize, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GlobalErrorDialogComponent } from '../app/global-error-dialog/global-error-dialog.component';
import { environment } from '../environments/environment';

/**
 * Custom error class for handling network connection errors (e.g., no internet).
 * Includes a `wasCaught` flag to track whether the error was already handled by the interceptor.
 */
class HttpNoNetworkConnectionError extends Error {
  // Flag to indicate if this error has been handled
  wasCaught = false;

  constructor() {
    super('No network connection');
  }
}

const ignoredServices = ['optin'];

function isApiError(error: { url: string }) {
  if (!('url' in error)) return false;
  if (ignoredServices.find((serviceApiId) => error.url.includes(serviceApiId))) return false;
  const isGatewayApiRequestRegex = /gateway\/api\/[a-zA-Z0-9]+\/v[0-9]+\/[a-zA-Z0-9/]+/;
  return isGatewayApiRequestRegex.test(error.url);
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  dialogRef?: MatDialogRef<GlobalErrorDialogComponent>;

  constructor(private readonly matDialog: MatDialog) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error) => {
        let errorMessage: string;
        // We only want to throw API-Errors and no SVG loading errors or sth else
        if (environment.production) throw error; // TODO: Remove this line to activate ErrorInterceptor on prod
        if (!isApiError(error)) throw error;

        // Handle network connection errors specifically
        if (checkNoNetworkConnection(error)) {
          // Set specific message for network errors
          errorMessage = `Es scheint keine Internetverbindung zu bestehen. Bitte überprüfen Sie Ihre Verbindung und versuchen Sie es erneut.`;

          // Create a custom network error object
          error = new HttpNoNetworkConnectionError();

          // Mark the error as caught to prevent duplicate handling
          error.wasCaught = true;
        } else if (is400ResponseError(error)) {
          // Explicitly skip handling 400 errors here (handled by tapValidationErrors operator)
          // This ensures that validation errors are handled in the component,
          // while other errors (e.g., 5xx, 4xx) fall through to the next case.
          errorMessage = `Die Anfrage konnte nicht verarbeitet werden`;
        } else {
          // For all other server errors or unexpected errors, display a generic error message.
          errorMessage = `Ein interner Fehler ist aufgetreten`;
        }

        if (errorMessage) {
          if (this.dialogRef === undefined) {
            this.dialogRef = this.matDialog.open(GlobalErrorDialogComponent, {
              width: '781px',
            });
          }

          this.dialogRef.componentInstance.addError({
            status: error.status,
            statusText: error.statusText,
            message: errorMessage,
            url: error.url,
            date: new Date().toISOString(),
          });

          this.dialogRef.componentInstance.closeDialog.subscribe(() => {
            this.dialogRef?.close();
          });

          this.dialogRef.afterClosed().pipe(
            finalize(() => {
              this.dialogRef = undefined;
            }),
          );
        }

        // Re-throw the error for handling in the component or a global error handler.
        throw error;
      }),
    );
  }
}

/**
 * Helper function to check if an error is likely due to a network connection issue.
 *
 * @param error The error object to check.
 * @returns `true` if it's likely a network error, `false` otherwise.
 */
function checkNoNetworkConnection(error: any): boolean {
  return (
    error instanceof HttpErrorResponse &&
    !error.headers.keys().length &&
    !error.ok &&
    !error.status &&
    !error.error.loaded &&
    !error.error.total
  );
}

/**
 * Checks if the given error is a 400 Bad Request error from the server.
 *
 * @param error The error object to check.
 * @returns True if the error is an HttpErrorResponse with status code 400 (Bad Request), false otherwise.
 */
function is400ResponseError(error: any) {
  return error instanceof HttpErrorResponse && error.status === HttpStatusCode.BadRequest;
}
