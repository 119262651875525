<div
  class="online-be-layout"
  aria-label="Mitglied werden Layout"
>
  <div class="online-be-layout__navigation">
    <ng-content select="[navigation]"></ng-content>
  </div>

  <main class="online-be-layout__content">
    <div class="online-be-layout__left">
      <ng-content select="[breadcrumb]"></ng-content>
      <h1>{{ 'services.onlineBe.label' | translate }}</h1>
      <p>
        {{ 'online_be.headline' | translate }}
      </p>
      <ul>
        <li [innerHTML]="'online_be.subheadline_list1' | translate"></li>
        <li [innerHTML]="'online_be.subheadline_list2' | translate"></li>
        <li [innerHTML]="'online_be.subheadline_list3' | translate"></li>
      </ul>
    </div>
    <div class="online-be-layout__right">
      <div class="online-be-layout__top">
        <div class="online-be-layout__top-content">
          <h2>{{ 'online_be.option1_headline' | translate }}</h2>
          <p>{{ 'online_be.option1_subheadline' | translate }}</p>
          <mkk-button
            variant="primary"
            [buttonText]="'online_be.option1_button' | translate"
            iconName="mkk-arrow-right"
            iconPosition="right"
            (click)="navigateToRegister()"
          ></mkk-button>
        </div>

        <mkk-illustration
          class="online-be-layout__illustration"
          illustrationName="mkk-online-be-register"
        />
      </div>
      <div class="online-be-layout__bottom">
        <div class="online-be-layout__bottom-content">
          <h2>{{ 'online_be.option2_headline' | translate }}</h2>
          <p>{{ 'online_be.option2_subheadline' | translate }}</p>
          <mkk-button
            variant="primary"
            [buttonText]="'online_be.option2_button' | translate"
            iconName="mkk-arrow-right"
            iconPosition="right"
            (click)="navigateToCallback()"
          ></mkk-button>
        </div>

        <mkk-illustration
          class="online-be-layout__illustration"
          illustrationName="mkk-online-be-callback"
        />
      </div>
    </div>
  </main>

  <ng-content select="[footer]"></ng-content>
</div>
