<mkk-overlay-wrapper
  headerColor="coral"
  class="mkk-global-error-dialog"
  (closeEvent)="closeDialog.emit()"
  noPadding
>
  <mkk-illustration
    header
    illustrationName="mkk-user-error"
    size="160px"
  ></mkk-illustration>
  <div class="mkk-global-error-dialog__content">
    <div class="global-error">
      <div class="global-error__content">
        <h1>{{ 'global-error.headline' | translate }}</h1>
        <span [innerHTML]="'global-error.description' | translate"></span>
      </div>
      @if (!hideErrorDetails) {
        <div class="global-error__error-details">
          @for (error of errors | async; track error.url) {
            <div class="error-detail">
              <div class="error">
                <mkk-icon
                  size="17px"
                  svgIconName="mkk-alert"
                ></mkk-icon>
                <span class="error-message">{{ error.message }}</span>
              </div>
              <div class="service-info">
                @if (extractMoreServiceInfo(error); as errorDetail) {
                  <div><b>Version:</b> {{ errorDetail.version }}</div>
                  <div><b>Service:</b> {{ errorDetail.entity }}</div>
                  <div><b>Detail:</b> {{ errorDetail.entityCall }}</div>
                  <div><b>Status:</b> {{ error.status }}</div>
                  <div><b>Datum:</b> {{ error.date | date: 'dd.MM.yyyy HH:mm:ss' }}</div>
                }
              </div>
            </div>
          }
        </div>
      }
      <div class="global-error__actions">
        <mkk-button
          [buttonText]="'global-error.sendErrorReport' | translate"
          class="auth__actions--full-width"
          iconName="mkk-mail"
          iconPosition="right"
          mat-dialog-close
          (click)="openMail()"
          fullWidth
        />
      </div>
    </div>
  </div>
</mkk-overlay-wrapper>
