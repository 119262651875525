import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding, withInMemoryScrolling } from '@angular/router';
import { AuupldApiModule } from '@mkk/au-upload/api';
import { AuslandskrankenscheinApiModule } from '@mkk/auslandskrankenschein/api';
import { AuthModule } from '@mkk/auth';
import { DokUploadApiModule } from '@mkk/dok-upload/api';
import { EgkOrderApiModule } from '@mkk/egk-order/api';
import { ElektronischeAuApiModule } from '@mkk/elektronische-au/api';
import { FaveAntragApiModule } from '@mkk/fave-antrag/api';
import { FaveBestandApiModule } from '@mkk/fave-bestand/api';
import { HimiSucheApiModule } from '@mkk/himi-suche/api';
import { InterceptorsModule } from '@mkk/interceptor';
import { KontaktformularApiModule } from '@mkk/kontaktformular/api';
import { MitgliedsbescheinigungApiModule } from '@mkk/mitgliedsbescheinigung/api';
import { OnlineBeApiModule } from '@mkk/online-be/api';
import { OnlineHistoryApiModule } from '@mkk/online-history/api';
import { PersonApiModule } from '@mkk/person/api';
import { BITGO_TOKEN } from '@mkk/shared/token';
import { UnfallfragebogenApiModule } from '@mkk/unfallfragebogen/api';
import { VersicherungsverhaeltnisApiModule } from '@mkk/versicherungsverhaeltnis/api';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthInterceptor, provideAuth } from 'angular-auth-oidc-client';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';

import { BabycareApiModule } from '@mkk/babycare/api';
import { EabApiModule } from '@mkk/eab/api';
import { LichtbildUploadApiModule } from '@mkk/lichtbild-upload/api';
import { MaintenanceModule } from '@mkk/maintenance';
import { MyProfileApiModule } from '@mkk/my-profile/api';
import { NonSmokingHeroesApiModule } from '@mkk/non-smoking-heroes/api';
import { OptInApiModule } from '@mkk/opt-in/api';
import { SepaApiModule } from '@mkk/sepa/api';
import { ErrorInterceptor } from '../interceptors/error.interceptor';

// Do not delete this comment - Generator Api Import Placeholder

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/locale/', '.json');
}

export function isBitgoSubroute() {
  const subRoute = window.location.pathname.split('/')[1];
  return subRoute === 'bitgo';
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      appRoutes,
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
      withComponentInputBinding(),
    ),
    provideAnimations(),
    importProvidersFrom(DokUploadApiModule.forRoot(environment)),
    importProvidersFrom(AuupldApiModule.forRoot(environment)),
    importProvidersFrom(AuslandskrankenscheinApiModule.forRoot(environment)),
    importProvidersFrom(BabycareApiModule.forRoot(environment)),
    importProvidersFrom(ElektronischeAuApiModule.forRoot(environment)),
    importProvidersFrom(KontaktformularApiModule.forRoot(environment)),
    importProvidersFrom(MitgliedsbescheinigungApiModule.forRoot(environment)),
    importProvidersFrom(OnlineHistoryApiModule.forRoot(environment)),
    importProvidersFrom(OnlineBeApiModule.forRoot(environment)),
    importProvidersFrom(FaveAntragApiModule.forRoot(environment)),
    importProvidersFrom(UnfallfragebogenApiModule.forRoot(environment)),
    importProvidersFrom(FaveBestandApiModule.forRoot(environment)),
    importProvidersFrom(PersonApiModule.forRoot(environment)),
    importProvidersFrom(VersicherungsverhaeltnisApiModule.forRoot(environment)),
    importProvidersFrom(HimiSucheApiModule.forRoot(environment)),
    importProvidersFrom(EgkOrderApiModule.forRoot(environment)),
    importProvidersFrom(EabApiModule.forRoot(environment)),
    importProvidersFrom(SepaApiModule.forRoot(environment)),
    importProvidersFrom(MyProfileApiModule.forRoot(environment)),
    importProvidersFrom(LichtbildUploadApiModule.forRoot(environment)),
    importProvidersFrom(NonSmokingHeroesApiModule.forRoot(environment)),
    importProvidersFrom(OptInApiModule.forRoot(environment)),
    // Do not delete this comment - Generator Api Module Placeholder
    { provide: BITGO_TOKEN, useValue: isBitgoSubroute() },
    importProvidersFrom(AuthModule.forRoot(environment)),
    importProvidersFrom(MaintenanceModule.forRoot(environment)),
    importProvidersFrom(InterceptorsModule.forRoot(environment)),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    provideAuth({
      config: environment.openIdConfig,
    }),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
        defaultLanguage: 'de',
      }),
    ),
  ],
};
