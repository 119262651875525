import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterData } from './footer.data';
import { TranslationPipe } from '@mkk/translate';
import { RouterLink } from '@angular/router';
import packageJson from '../../../package.json';

@Component({
  selector: 'mkk-footer',
  standalone: true,
  imports: [CommonModule, TranslationPipe, RouterLink],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  @Input()
  items: FooterData[] = [];

  public readonly version: string = packageJson.version;
  readonly currentYear = new Date().getFullYear();
}
