import { CommonModule } from '@angular/common';
import { Component, HostListener, Input, signal } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { HeaderWithAuthComponent, LoginService, SessionService } from '@mkk/auth';
import {
  BannerComponent,
  BreadCrumb,
  BreadcrumbComponent,
  HeaderNavigationComponent,
  HeaderVariant,
  IconButtonComponent,
  LoadingComponent,
  MaintenancePageComponent,
  NavigationComponent,
  UpcomingMaintenance,
  UserData,
} from '@mkk/ui';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { securedRoutes } from './../app.routes';
import navItems from './../navigation.data';
import { InsuredPersonService } from '@mkk/person/component';
import { environment } from '../../environments/environment';
import { FooterComponent } from './../footer/footer.component';
import { footerData } from './../footer/footer.data';
import { TranslationPipe } from '@mkk/translate';
import { HeaderContentLayoutComponent } from './header-content-layout/header-content-layout.component';
import { MaintenanceLayoutComponent } from './maintenance-layout/maintenance-layout.component';
import { FullscreenLayoutComponent } from './fullscreen-layout/fullscreen-layout.component';
import { MkkRoute, MkkRouteData } from '@mkk/meine-kkh/domain';
import { MaintenanceService, UpcomingMaintenanceService } from '@mkk/maintenance';
import { LayoutStateService } from './layout-state.service';
import { isBefore } from 'date-fns';
import { OnlineBeLayoutComponent } from './online-be-layout/online-be-layout.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    HeaderWithAuthComponent,
    IconButtonComponent,
    NavigationComponent,
    FooterComponent,
    TranslationPipe,
    BreadcrumbComponent,
    HeaderContentLayoutComponent,
    FullscreenLayoutComponent,
    MaintenanceLayoutComponent,
    OnlineBeLayoutComponent,
    LoadingComponent,
    HeaderNavigationComponent,
    BannerComponent,
    MaintenancePageComponent,
  ],
  selector: 'mkk-layout',
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
})
export class LayoutComponent {
  @Input({ required: true })
  breadcrumbItems: BreadCrumb[] = [];

  @Input({ required: true })
  routeData!: Required<MkkRouteData>;

  readonly isDebugMode = !environment.production;
  showDebugNavigation = signal(false);
  showNavigation = signal(false);
  loggedIn$ = new BehaviorSubject<boolean | undefined>(undefined);
  isOneTimeLoggedIn$ = new Observable<boolean>();
  userData$: Observable<UserData>;
  footerData = footerData;
  upcomingMaintenance$ = new BehaviorSubject<UpcomingMaintenance | null>(null);
  showMaintenanceBanner = true;
  showReleaseBanner = isBefore(new Date(), new Date('2025-02-01'));
  waitingFor2FAConfirmation = () => this.loginService.isWaitingFor2FAConfirmation();

  readonly navItems = navItems;
  protected readonly debugRoutes: { route: MkkRoute; isSecured?: boolean }[] = securedRoutes;

  constructor(
    private personService: InsuredPersonService,
    private loginService: LoginService,
    private upcomingMaintenanceService: UpcomingMaintenanceService,
    private maintenanceService: MaintenanceService,
    private readonly sessionService: SessionService,
    public layoutState: LayoutStateService,
    public router: Router,
  ) {
    this.upcomingMaintenance$ = this.upcomingMaintenanceService.upcomingMaintenance$;
    this.loggedIn$ = loginService.loggedIn$;

    // Hide navigation for otl user
    this.isOneTimeLoggedIn$ = loginService.loggedInIssuer$.pipe(
      map(
        (loggedInIssuer) => loggedInIssuer === 'favebe' || loggedInIssuer === 'unfall' || loggedInIssuer === 'egkupl',
      ),
    );
    this.userData$ = this.personService.insuredPerson$.pipe(
      map(({ familyName, givenName, kvnr }) => ({
        familyName,
        givenName,
        kvnr,
      })),
    );
  }

  isSecuredRoute() {
    return this.debugRoutes.some((debugRoute) => this.router.url.includes(debugRoute.route.path ?? ''));
  }

  closeMaintenanceBanner() {
    this.showMaintenanceBanner = false;
  }

  closeReleaseBanner() {
    this.showReleaseBanner = false;
  }

  signIn() {
    this.toggleNav(false);
    this.loginService.login();
  }

  signOut() {
    this.toggleNav(false);
    this.loginService.logout();
  }

  toggleDebugNav(showNav?: boolean) {
    this.showDebugNavigation.update((currentNavVisibility) => showNav ?? !currentNavVisibility);
  }

  toggleNav(showNav?: boolean) {
    this.showNavigation.update((currentNavVisibility) => {
      const isVisible = showNav ?? !currentNavVisibility;

      if (isVisible) {
        document.body.classList.add('no-scroll');
        document.documentElement.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
        document.documentElement.classList.remove('no-scroll');
      }

      return isVisible;
    });
  }

  getNavItems() {
    return this.loggedIn$.value ? navItems.navItemsLoggedIn : navItems.navItemsLoggedOut;
  }

  getHeaderVariant(): HeaderVariant {
    switch (this.routeData.layout) {
      case 'landing-page':
        return 'login-header';
      case 'register-header':
        return 'register-header';
      case 'full':
        return 'service-info-header';
      default:
        return 'service-header';
    }
  }

  // For faster service access
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (!this.isDebugMode) return;

    if (event.ctrlKey && event.shiftKey && event.key === '>') {
      this.showDebugNavigation.update((currentNavVisibility) => !currentNavVisibility);
    }
  }

  debug_simulateSessionTimeout() {
    this.sessionService.settings = {
      confirmation2FATimeoutAfterMinutes: 0.1,
      timeoutAfterMinutes: 0.4,
      closedAfterMinutes: 200,
      dialogTimerInMinutes: 0.2,
      toleranceInMinutes: 0.1,
      sessionCheckIntervalInSeconds: 0.5,
    };
    this.sessionService.restartTracking();
  }

  debug_simulateSessionClosed() {
    this.sessionService.settings = {
      confirmation2FATimeoutAfterMinutes: 0.1,
      timeoutAfterMinutes: 1,
      closedAfterMinutes: 0.2,
      dialogTimerInMinutes: 0.1,
      toleranceInMinutes: 0.1,
      sessionCheckIntervalInSeconds: 0.5,
    };
    this.sessionService.restartTracking();
  }

  debug_simulate2faClosed() {
    this.sessionService.settings = {
      confirmation2FATimeoutAfterMinutes: 0.01,
      timeoutAfterMinutes: 10,
      closedAfterMinutes: 100,
      dialogTimerInMinutes: 0.1,
      toleranceInMinutes: 0.1,
      sessionCheckIntervalInSeconds: 0.5,
    };
    this.sessionService.restartTracking();
  }
}
