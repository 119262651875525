import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '../button/button.component';
import { IconComponent } from '../icon/icon.component';
import { TranslationPipe } from '@mkk/translate';
import { InitialsPipe } from '../pipes/initials.pipe';
import { NavItem, UserData } from '../types/navigation.types';
import { Icons } from '../types/types';
import { IconButtonComponent } from '../icon-button/icon-button.component';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'mkk-navigation',
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
    ButtonComponent,
    IconButtonComponent,
    TranslationPipe,
    InitialsPipe,
    RouterLink,
    RouterLinkActive,
  ],
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.scss',
})
export class NavigationComponent {
  @Input() navItems!: NavItem[][];
  @Input() userData?: UserData | null;

  isLoggedIn = () => this.userData && this.userData?.kvnr;

  @Output() clickSignIn: EventEmitter<void> = new EventEmitter();
  @Output() clickSignOut: EventEmitter<void> = new EventEmitter();
  @Output() clickClose: EventEmitter<void> = new EventEmitter();

  isExternalLink(href: string) {
    return href.startsWith('http');
  }

  getLinkIcon(href: string): Icons {
    // Check if is external link
    if (this.isExternalLink(href)) {
      return 'mkk-arrow-upper-right';
    }

    return 'mkk-chevron-right';
  }

  getLinkTarget(href: string): '_blank' | '_self' {
    // Check if is external link
    if (href.startsWith('http')) {
      return '_blank';
    }

    return '_self';
  }

  routeToExt(navItem: NavItem) {
    if (this.isExternalLink(navItem.href)) window.open(navItem.href, '_blank');
    this.clickClose.emit();
  }
}
