import { TranslationKeys } from '@mkk/translate';

export type FooterData = {
  label: TranslationKeys;
  href: string;
  target?: '_blank';
};

export const footerData: FooterData[] = [
  {
    label: 'footer.imprint',
    href: 'https://www.kkh.de/impressum',
    target: '_blank',
  },
  {
    label: 'footer.privacy',
    href: '/datenschutz',
  },
  {
    label: 'footer.agb',
    href: 'https://www.kkh.de/digitale-gesundheit/digitale-services/app/rechtliches/nutzungsbedingungen',
    target: '_blank',
  },
  {
    label: 'footer.accessibility',
    href: 'https://www.kkh.de/digitale-gesundheit/digitale-services/meine-kkh/rechtliches/barrierefreiheit',
    target: '_blank',
  },
  {
    label: 'footer.kkh',
    href: 'https://www.kkh.de',
    target: '_blank',
  },
];
