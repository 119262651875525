import { Injectable } from '@angular/core';
import { UpcomingMaintenance } from '@mkk/types';
import { isWithinInterval } from 'date-fns';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UpcomingMaintenanceService {
  now = new Date();
  start = new Date('2024-11-28T13:00:00'); // 28.11.2024, 13:00
  end = new Date('2024-11-30T06:00:00'); // 30.11.2024, 06:00

  // Determine if the current date and time is within the specified range
  isWithinRange = isWithinInterval(this.now, {
    start: this.start,
    end: this.end,
  });

  // Set the Text that is shown in the banner
  upcomingMaintenanceObject: UpcomingMaintenance = {
    weekDay: 'Samstag',
    fromDate: '30.11.2024',
    fromTime: '06:00',
    untilTime: '16:00',
  };

  // Show the banner, if the current date and time is within the specified range, otherwise: don't
  upcomingMaintenance$ = new BehaviorSubject<UpcomingMaintenance | null>(
    this.isWithinRange ? this.upcomingMaintenanceObject : null,
  );
}
