<div
  class="mkk-maintenance-layout"
  aria-label="Maintenance layout"
>
  <div class="mkk-maintenance-layout__header">
    <ng-content select="[navigation]"></ng-content>
  </div>

  <ng-content select="[content]"></ng-content>

  <ng-content select="[footer]"></ng-content>
</div>
