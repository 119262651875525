import { MkkRoute, services } from '@mkk/meine-kkh/domain';
import { CustomTitleResolver } from '../../resolver/custom-title.resolver';
import { IsLoggedInGuard } from '@mkk/auth';

export const stayHealthyRoutes: MkkRoute[] = [
  {
    title: CustomTitleResolver,
    data: {
      titleKey: services.nonSmokingHeroes.title,
      header: {
        headline: services.nonSmokingHeroes.title,
        label: services.nonSmokingHeroes.description,
        illustration: 'mkk-non-smoking-heroes',
      },
      layout: 'full',
    },
    path: 'non-smoking-heroes',
    canActivate: [IsLoggedInGuard],

    loadComponent: () =>
      import('@mkk/non-smoking-heroes/component').then(({ NonSmokingHeroesComponent }) => NonSmokingHeroesComponent),
    loadChildren: () => import('@mkk/non-smoking-heroes/component').then(({ routes }) => routes),
  },
];
