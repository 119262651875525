import { IsLoggedInGuard } from '@mkk/auth';
import { MkkRoute, services } from '@mkk/meine-kkh/domain';
import { CustomTitleResolver } from '../../resolver/custom-title.resolver';

export const sickDaysRoutes: MkkRoute[] = [
  {
    title: CustomTitleResolver,
    data: {
      titleKey: services.sickDaysUpload.title,
      header: {
        headline: services.sickDaysUpload.title,
        label: services.sickDaysUpload.description,
        illustration: services.sickDaysUpload.illustration,
      },
    },
    path: 'krankmeldung-hochladen',
    canActivate: [IsLoggedInGuard],
    loadComponent: () => import('@mkk/au-upload/component').then(({ AuUploadComponent }) => AuUploadComponent),
    loadChildren: () => import('@mkk/au-upload/component').then(({ routes }) => routes),
  },
  {
    title: CustomTitleResolver,
    data: {
      titleKey: services.eau.title,
      header: {
        headline: services.eau.title,
        label: services.eau.description,
        illustration: services.eau.illustration,
      },
      layout: 'full',
    },
    path: 'elektronisch-empfangene-krankmeldungen',
    canActivate: [IsLoggedInGuard],

    loadComponent: () =>
      import('@mkk/elektronische-au/component').then(({ ElektronischeAUComponent }) => ElektronischeAUComponent),
    loadChildren: () => import('@mkk/elektronische-au/component').then(({ routes }) => routes),
  },
];
