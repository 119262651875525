<div class="mkk-banner">
  <div class="mkk-banner__text">
    <mkk-icon
      svgIconName="mkk-warning"
      size="16px"
    />
    <p
      class="mkk-banner__heading"
      [innerHTML]="'common.labels.hint' | translate"
    ></p>
    <p
      class="mkk-banner__hint"
      [innerHTML]="bannerText"
    ></p>
  </div>
  <mkk-icon-button
    iconName="mkk-cross"
    variant="not-outlined"
    (click)="closeBanner.emit()"
  />
</div>
