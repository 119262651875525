/* eslint-disable @typescript-eslint/no-explicit-any */
import { EnvironmentInjector, Inject, Injectable, runInInjectionContext } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { ORIGIN_URL_WHITELIST } from '@mkk/auth';

/* eslint-disable-next-line */
export const originRegex = /(https?:\/\/[^\/\s]+)(\/.*)/;

@Injectable()
export class HttpXsrfTokenStorage {
  private tokenMap = new Map<string, string>();

  setToken(tokenOrigin: string, newToken: string) {
    this.tokenMap.set(tokenOrigin, newToken);
  }

  getToken(tokenOrigin: string) {
    return this.tokenMap.get(tokenOrigin);
  }
}

/**
 * `HttpInterceptor` which adds an XSRF token to eligible outgoing requests.
 */
@Injectable()
export class HttpXsrfRequestInterceptor implements HttpInterceptor {
  constructor(
    private injector: EnvironmentInjector,
    private tokenStorage: HttpXsrfTokenStorage,
    @Inject(ORIGIN_URL_WHITELIST) private readonly originWhiteList: Array<string>,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return runInInjectionContext(this.injector, () => {
      req = req.clone({
        withCredentials: true,
      });

      const lcUrl = req.url.toLowerCase();

      const originMatches = lcUrl.match(originRegex);

      if (originMatches === null) {
        return next.handle(req);
      }

      const origin = originMatches[1];

      if (
        req.method === 'GET' ||
        req.method === 'HEAD' ||
        req.method === 'OPTIONS' ||
        req.method === 'TRACE' ||
        !this.originWhiteList.includes(origin)
      ) {
        return next.handle(req);
      }

      const token = this.tokenStorage.getToken(origin);
      const headerName = 'X-XSRF-TOKEN';

      if (token != null && !req.headers.has(headerName)) {
        req = req.clone({ headers: req.headers.set(headerName, token) });
      }

      return next.handle(req);
    });
  }
}

@Injectable()
export class HttpXsrfResponseInterceptor implements HttpInterceptor {
  constructor(
    private injector: EnvironmentInjector,
    private tokenStorage: HttpXsrfTokenStorage,
    @Inject(ORIGIN_URL_WHITELIST) private readonly originWhiteList: Array<string>,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return runInInjectionContext(this.injector, () =>
      next.handle(req).pipe(
        map((event) => {
          if (event instanceof HttpResponse) {
            const url = event.url;

            if (url === null) {
              return event;
            }

            const originMatches = url.toLowerCase().match(originRegex);

            if (originMatches === null) {
              return event;
            }

            const origin = originMatches[1];

            if (!this.originWhiteList.includes(origin)) {
              return event;
            }

            const token = event.headers.get('X-XSRF-TOKEN'.toLowerCase());

            if (token !== null) {
              this.tokenStorage.setToken(origin, token);
            }
          }

          return event;
        }),
      ),
    );
  }
}
