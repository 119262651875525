<div
  class="mkk-header-content-layout"
  aria-label="Header content layout"
>
  <div
    class="mkk-header-content-layout__header"
    [ngStyle]="{
      background: 'var(' + color + ')',
    }"
  >
    <ng-content select="[navigation]"></ng-content>
    <header>
      <ng-content select="[header]"></ng-content>
    </header>
  </div>
  <div class="mkk-main">
    <main
      class="mkk-main__content"
      [class.mkk-main__content--wide]="contentWidth === 'wide'"
    >
      <ng-content select="[breadcrumb]"></ng-content>
      <ng-content select="[router]"></ng-content>
    </main>
  </div>
  <ng-content select="[footer]"></ng-content>
</div>
