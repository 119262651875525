import { MkkSessionSettings } from './env.types';

export const defaultSessionSettings: MkkSessionSettings = {
  confirmation2FATimeoutAfterMinutes: 15,
  timeoutAfterMinutes: 30,
  closedAfterMinutes: 180,
  dialogTimerInMinutes: 1,
  toleranceInMinutes: 5,
  sessionCheckIntervalInSeconds: 10,
};
