import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbComponent, HeaderNavigationComponent, IconButtonComponent, NavigationComponent } from '@mkk/ui';
import { FooterComponent } from '../../footer/footer.component';
import { HeaderWithAuthComponent } from '@mkk/auth';
import { RouterOutlet } from '@angular/router';
import { LayoutBackgroundColor } from '@mkk/meine-kkh/domain';

@Component({
  selector: 'mkk-fullscreen-layout',
  standalone: true,
  imports: [
    CommonModule,
    BreadcrumbComponent,
    FooterComponent,
    HeaderWithAuthComponent,
    IconButtonComponent,
    NavigationComponent,
    RouterOutlet,
    HeaderNavigationComponent,
  ],
  templateUrl: './fullscreen-layout.component.html',
  styleUrl: './fullscreen-layout.component.scss',
})
export class FullscreenLayoutComponent {
  @Input()
  color: LayoutBackgroundColor = '--kkhds-color-palette-light-blue-200';
}
