import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { LoginService } from '@mkk/auth';
import { BreadCrumb, Layout } from '@mkk/ui';
import { filter } from 'rxjs';
import { LayoutComponent } from './layout/layout.component';
import {
  ContentWidth,
  getMainCategory,
  HeaderData,
  LayoutBackgroundColor,
  MkkRouteData,
  services,
} from '@mkk/meine-kkh/domain';
import { MaintenanceService } from '@mkk/maintenance';

@Component({
  standalone: true,
  imports: [CommonModule, LayoutComponent, RouterOutlet],
  selector: 'mkk-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  contentWidth: ContentWidth = 'default';
  routeLayout: Layout = 'loading';
  routeLayoutAccentColor: LayoutBackgroundColor = '--kkhds-color-palette-light-blue-200';
  routeHideBreadcrumb = false;
  routeHeaderData: HeaderData = {
    headline: '',
    label: '',
    illustration: 'mkk-start',
    contentVariant: 'text-only',
  };
  breadcrumbItems: BreadCrumb[] = [];

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private maintenanceService: MaintenanceService,
    private loginService: LoginService,
  ) {
    this.analyzeRouterData();

    // If the maintenance service returns true, route to "Wartungsseite" and disable any further routing.
    if (this.maintenanceService.maintenance$.value) {
      this.router.navigate(['/wartung']);

      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.router.navigate(['/wartung']);
        }
      });
    }
  }

  private createBreadcrumbs(
    route: ActivatedRoute,
    url = '',
    breadcrumbs: BreadCrumb[] = [
      {
        label: 'Home',
        url: '/',
      },
    ],
  ): BreadCrumb[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    // max. 3 levels Home / Category / Servicename
    if (breadcrumbs.length === 3) return breadcrumbs;

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map((segment) => segment.path).join('/');

      const label = child.snapshot.title;
      const snapshotUrl = child.snapshot.url;

      const foundService = Object.values(services).find(({ href }) => href && href.includes(routeURL));

      // Add category breadrrumb item based on assigned Tag
      if (foundService && breadcrumbs.length === 1 && this.loginService.loggedIn$.value) {
        const category = getMainCategory(foundService.categoryTags);

        if (category) {
          breadcrumbs.push({ label: category?.label, url: category.href });
          return this.createBreadcrumbs(route, url, breadcrumbs);
        }
      }

      if (label !== null && label !== undefined && snapshotUrl.length > 0) {
        breadcrumbs.push({ label, url });
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }

    if (breadcrumbs.length === 1) return [];

    return breadcrumbs;
  }

  setETrackerScripts() {
    document.querySelectorAll('script[src*="etracker"]').forEach((el) => el.remove());
    const node = document.createElement('script'); // creates the script tag
    node.id = '_etLoader';
    node.setAttribute('data-block-cookies', 'true');
    node.setAttribute('data-secure-code', 'PEgZl3');
    node.src = '//code.etracker.com/code/e.js'; // sets the source (insert url in between quotes)
    node.type = 'text/javascript'; // set the script type
    node.async = true; // makes script run asynchronously
    node.charset = 'utf-8';
    // append to head of document
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  setETrackerPageInfo(pagename: string, areas: string, tval?: number) {
    document.getElementById('page-info')?.remove();
    const pageInfo = document.createElement('script'); // creates the script tag
    pageInfo.id = 'page-info';
    pageInfo.text = `
      var et_pagename = "${pagename}";
      var et_areas = "${areas}";
      var et_tval = 0;
    `;
    // append to head of document
    document.getElementsByTagName('head')[0].appendChild(pageInfo);
  }

  collectRouteData(route: ActivatedRoute): void {
    // Traverse to the active child route to gather its data
    let activeRoute = route;

    while (activeRoute.firstChild) {
      activeRoute = activeRoute.firstChild;

      const { layout, header, contentWidth, layoutAccentColor, hideBreadcrumb } = activeRoute.snapshot
        .data as MkkRouteData;

      this.routeLayout = layout ?? 'service';
      this.routeHeaderData = header ?? this.routeHeaderData;
      this.contentWidth = contentWidth ?? 'default';
      this.routeLayoutAccentColor = layoutAccentColor ?? '--kkhds-color-palette-light-blue-200';
      this.routeHideBreadcrumb = hideBreadcrumb ?? false;

      this.setETrackerPageInfo(location.pathname, 'area');

      // Current approach is quick: Can also be refactored with own routes per state (/ and /start with own headerData info)
      if (!this.loginService.loggedIn$.value && location.pathname === '/')
        this.routeHeaderData = {
          ...this.routeHeaderData,
          illustration: 'mkk-start',
        }; // In LoggedOut State always show start illustration
    }
  }

  analyzeRouterData() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.collectRouteData(this.activatedRoute);
      this.breadcrumbItems = this.createBreadcrumbs(this.activatedRoute.root);
    });
  }
}
