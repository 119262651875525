import { categories, MkkRoute, services } from '@mkk/meine-kkh/domain';
import { CustomTitleResolver } from '../../resolver/custom-title.resolver';
import { filterServices } from '@mkk/ui';
import { IsLoggedInGuard } from '@mkk/auth';

export const categoryOverviewRoutes: MkkRoute[] = [
  {
    title: CustomTitleResolver,
    data: {
      titleKey: categories.sickDays.label,
      contentWidth: 'wide',
      services: filterServices(services, categories.sickDays),
      header: {
        headline: categories.sickDays.label,
        illustration: 'mkk-ballon',
      },
    },
    path: categories.sickDays.href,
    canActivate: [IsLoggedInGuard],
    loadComponent: () =>
      import('../category-services-overview/category-services-overview.component').then(
        ({ CategoryServicesOverviewComponent }) => CategoryServicesOverviewComponent,
      ),
  },
  {
    title: CustomTitleResolver,
    data: {
      titleKey: categories.certificates.label,
      contentWidth: 'wide',
      services: filterServices(services, categories.certificates),
      header: {
        headline: categories.certificates.label,
        illustration: 'mkk-ballon',
      },
    },
    path: categories.certificates.href,
    canActivate: [IsLoggedInGuard],
    loadComponent: () =>
      import('../category-services-overview/category-services-overview.component').then(
        ({ CategoryServicesOverviewComponent }) => CategoryServicesOverviewComponent,
      ),
  },
  {
    title: CustomTitleResolver,
    data: {
      titleKey: categories.myArea.label,
      header: {
        headline: categories.myArea.label,
        illustration: 'mkk-profile',
      },
      services: filterServices(services, categories.myArea),
    },
    path: categories.myArea.href,
    canActivate: [IsLoggedInGuard],
    loadComponent: () => import('@mkk/my-profile/component').then(({ MyProfileComponent }) => MyProfileComponent),
  },
  {
    title: CustomTitleResolver,
    data: {
      titleKey: categories.advantages.label,
      contentWidth: 'wide',
      services: filterServices(services, categories.advantages),
      header: {
        headline: categories.advantages.label,
        illustration: 'mkk-ballon',
      },
    },
    path: categories.advantages.href,
    canActivate: [IsLoggedInGuard],
    loadComponent: () =>
      import('../category-services-overview/category-services-overview.component').then(
        ({ CategoryServicesOverviewComponent }) => CategoryServicesOverviewComponent,
      ),
  },
  {
    title: CustomTitleResolver,
    data: {
      titleKey: categories.healthGuides.label,
      contentWidth: 'wide',
      services: filterServices(services, categories.healthGuides),
      header: {
        headline: categories.healthGuides.label,
        illustration: 'mkk-ballon',
      },
    },
    path: categories.healthGuides.href,
    canActivate: [IsLoggedInGuard],
    loadComponent: () =>
      import('../category-services-overview/category-services-overview.component').then(
        ({ CategoryServicesOverviewComponent }) => CategoryServicesOverviewComponent,
      ),
  },
  {
    title: CustomTitleResolver,
    data: {
      titleKey: categories.stayHealthy.label,
      contentWidth: 'wide',
      services: filterServices(services, categories.stayHealthy),
      header: {
        headline: categories.stayHealthy.label,
        illustration: 'mkk-ballon',
      },
    },
    path: categories.stayHealthy.href,
    loadComponent: () =>
      import('../category-services-overview/category-services-overview.component').then(
        ({ CategoryServicesOverviewComponent }) => CategoryServicesOverviewComponent,
      ),
  },
];
