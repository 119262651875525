<mkk-layout
  [breadcrumbItems]="breadcrumbItems"
  [routeData]="{
    header: routeHeaderData,
    layoutAccentColor: routeLayoutAccentColor,
    hideBreadcrumb: routeHideBreadcrumb,
    layout: routeLayout,
    contentWidth: contentWidth,
    services: [],
  }"
>
  @switch (routeLayout) {
    @case ('full') {
      <div fullscreen>
        <router-outlet></router-outlet>
      </div>
    }
    @case ('online-be') {
      <div onbeit>
        <router-outlet></router-outlet>
      </div>
    }
    @case ('maintenance') {
      <div
        maintenance
        [style]="{ flex: 1 }"
      >
        <router-outlet></router-outlet>
      </div>
    }
    @case (['service', 'landing-page', 'register-header'].includes(routeLayout) && routeLayout) {
      <div header-content>
        <router-outlet></router-outlet>
      </div>
    }
  }
</mkk-layout>
