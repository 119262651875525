<div
  class="mkk-fullscreen-layout"
  aria-label="Fullscreen layout"
  [ngStyle]="{
    backgroundImage: 'linear-gradient(to right, var(--kkhds-color-palette-white) 50%, var(' + color + ') 50%)',
  }"
>
  <div
    class="mkk-fullscreen-layout__navigation"
    [ngStyle]="{
      background: 'var(' + color + ')',
    }"
  >
    <ng-content select="[navigation]"></ng-content>
  </div>
  <div
    class="mkk-fullscreen-layout__header"
    [ngStyle]="{
      background: 'var(' + color + ')',
    }"
  >
    <ng-content select="[header]"></ng-content>
  </div>
  <div class="mkk-fullscreen-layout__content-wrapper">
    <main>
      <ng-content select="[breadcrumb]"></ng-content>
      <div>
        <ng-content select="[router]"></ng-content>
      </div>
    </main>
  </div>
</div>
<ng-content select="[footer]"></ng-content>
