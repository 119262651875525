import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IconComponent } from '../icon/icon.component';
import { TranslationPipe } from '@mkk/translate';
import { IconButtonComponent } from '../icon-button/icon-button.component';

@Component({
  selector: 'mkk-banner',
  standalone: true,
  imports: [CommonModule, IconComponent, IconButtonComponent, TranslationPipe],
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent {
  /**
   * The object that specifies from which on and until the portal "Meine KKH" will be unavailable.
   */
  @Input()
  bannerText = '';

  /**
   * Event that gets triggered, when user presses the close button
   */
  @Output()
  closeBanner: EventEmitter<void> = new EventEmitter();
}
