import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent, IconComponent, IllustrationComponent, OverlayWrapperComponent } from '@mkk/ui';
import { TranslationPipe } from '@mkk/translate';
import { MatDialogClose } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { MkkEnvironment } from '@mkk/env';
import { ENVIRONMENT } from '@mkk/auth';
import { environment } from '../../environments/environment';

type GlobalError = { url: string; message: string; statusText: string; status: string; date: string };

const SUPPORT_MAIL = environment.production ? 'mailto:service@kkh.de' : 'mailto:peter.hueer@adesso.de';

@Component({
  selector: 'mkk-error-dialog',
  standalone: true,
  imports: [
    CommonModule,
    OverlayWrapperComponent,
    IconComponent,
    ButtonComponent,
    TranslationPipe,
    MatDialogClose,
    IllustrationComponent,
  ],
  templateUrl: './global-error-dialog.component.html',
  styleUrl: './global-error-dialog.component.scss',
})
export class GlobalErrorDialogComponent implements OnInit {
  hideErrorDetails = true;
  errors = new BehaviorSubject<Array<GlobalError>>([]);

  @Output()
  closeDialog: EventEmitter<void> = new EventEmitter();

  constructor(@Inject(ENVIRONMENT) readonly env: MkkEnvironment) {
    this.hideErrorDetails = env.production ?? true;
  }

  ngOnInit() {
    this.errors.subscribe((errors) => {
      const mailLink = document.querySelector('a[href^="mailto:"] ');
      mailLink?.setAttribute('href', this.generateMailTo(errors));
    });
  }

  addError(error: GlobalError) {
    this.errors.next([...this.errors.value, error]);
  }

  extractMoreServiceInfo(error: GlobalError):
    | {
        entity: string;
        version: string;
        entityCall: string;
        date: string;
        status: string;
      }
    | undefined {
    const regex = /\/api\/([^/]+)\/([^/]+)\/(.+)/;
    const match = error.url.match(regex);

    if (match) {
      const [, entity, version, entityCall] = match;
      return { entity: entity, version, entityCall, date: error.date, status: error.status };
    } else {
      return undefined;
    }
  }

  generateMailTo(errors: GlobalError[]) {
    console.log(errors);
    const errorDetails = errors.map(this.extractMoreServiceInfo).filter((e) => !!e);

    console.log(errorDetails);

    if (errorDetails.length > 0) {
      return `${SUPPORT_MAIL}?subject=Meine-KKH Frontend Fehler&body=${JSON.stringify(errorDetails)}`;
    }

    return SUPPORT_MAIL;
  }

  openMail() {
    window.location.href = this.generateMailTo(this.errors.value);
  }
}
