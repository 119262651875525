<ol class="mkk-breadcrumb">
  @for (breadcrumb of breadcrumbs; track breadcrumb.label) {
    <li>
      <a [routerLink]="breadcrumb.url">
        <mkk-icon
          svgIconName="mkk-arrow-left"
          size="16px"
        />
        <span>
          {{ breadcrumb.label | translate }}
        </span>
      </a>
    </li>
  }
</ol>
