import { MkkEnvironment } from './env.types';
import { defaultSessionSettings } from './default-session.settings';

export const environment: MkkEnvironment = {
  name: 'prod',
  production: true,
  authLevel: '2fa',
  apiUrl: 'https://meine.kkh.de/gateway/api',
  gatewayOrigin: 'https://meine.kkh.de',
  gatewayBasePath: '/gateway',
  sessionSettings: defaultSessionSettings,
};
