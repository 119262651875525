import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  BreadcrumbComponent,
  ButtonComponent,
  HeaderNavigationComponent,
  IconButtonComponent,
  IllustrationComponent,
  NavigationComponent,
} from '@mkk/ui';
import { FooterComponent } from '../../footer/footer.component';
import { HeaderWithAuthComponent } from '@mkk/auth';
import { Router, RouterOutlet } from '@angular/router';
import { TranslationPipe } from '@mkk/translate';

@Component({
  selector: 'mkk-online-be-layout',
  standalone: true,
  imports: [
    CommonModule,
    BreadcrumbComponent,
    FooterComponent,
    HeaderWithAuthComponent,
    IconButtonComponent,
    ButtonComponent,
    IllustrationComponent,
    NavigationComponent,
    RouterOutlet,
    HeaderNavigationComponent,
    TranslationPipe,
  ],
  templateUrl: './online-be-layout.component.html',
  styleUrl: './online-be-layout.component.scss',
})
export class OnlineBeLayoutComponent {
  constructor(private router: Router) {}

  navigateToRegister() {
    this.router.navigate(['mitglied-werden/beitritt']);
  }

  navigateToCallback() {
    this.router.navigate(['mitglied-werden/rueckruf']);
  }
}
