import { NavItem } from '@mkk/ui';
import { categories, services } from '@mkk/meine-kkh/domain';

const navItemsExternal: NavItem[] = [
  { label: 'ui.misc.nav.externals.imprint', href: 'https://www.kkh.de/impressum' },
  { label: 'ui.misc.nav.externals.privacy', href: '/datenschutz' },
  {
    label: 'ui.misc.nav.externals.agb',
    href: 'https://www.kkh.de/digitale-gesundheit/digitale-services/app/rechtliches/nutzungsbedingungen',
  },
  { label: 'ui.misc.nav.externals.kkh', href: 'https://www.kkh.de' },
];

const navItemsLoggedOut: NavItem[][] = [
  [
    {
      label: 'ui.misc.nav.loggedOut.becomeMember',
      href: services.onlineBe.href,
    },
  ],
  navItemsExternal,
];

const navItemsLoggedIn: NavItem[][] = [
  [
    { label: 'ui.misc.nav.loggedIn.certificates', href: categories.certificates.href },
    { label: 'ui.misc.nav.loggedIn.sickLeave', href: categories.sickDays.href },
    { label: 'ui.misc.nav.loggedIn.advantages', href: categories.advantages.href, bold: true },
    { label: 'ui.misc.nav.loggedIn.stayHealthy', href: categories.stayHealthy.href },
    { label: 'ui.misc.nav.loggedIn.healthGuides', href: categories.healthGuides.href },
  ],
  navItemsExternal,
];

export default { navItemsLoggedIn, navItemsLoggedOut };
