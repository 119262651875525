import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceService {
  // Show maintenance page on 30.11.2024 between 06:00 and 16:00
  now = new Date();
  isSpecifiedDate = this.now.getMonth() === 10 && this.now.getDate() === 30; // 30.11.2024 (November is month 10 (0-indexed))
  isBetweenSpecifiedTime = this.now.getHours() >= 6 && this.now.getHours() < 16; // Between 06:00 and 16:00

  maintenance$ = new BehaviorSubject<boolean>(this.isSpecifiedDate && this.isBetweenSpecifiedTime ? true : false);
}
