import { IsLoggedInGuard } from '@mkk/auth';
import { MkkRoute, services } from '@mkk/meine-kkh/domain';
import { CustomTitleResolver } from '../../resolver/custom-title.resolver';

export const myAreaRoutes: MkkRoute[] = [
  {
    title: CustomTitleResolver,
    data: {
      titleKey: services.history.title,
      header: {
        headline: services.history.title,
        label: services.history.description,
        illustration: services.history.illustration,
      },
      layout: 'service',
    },
    path: 'meine-online-historie',
    canActivate: [IsLoggedInGuard],

    loadComponent: () => import('@mkk/online-history/component').then(({ LayoutComponent }) => LayoutComponent),
    loadChildren: () => import('@mkk/online-history/component').then(({ routes }) => routes),
  },
  {
    title: CustomTitleResolver,
    data: {
      titleKey: services.lightPicture.title,
      header: {
        headline: services.lightPicture.title,
        label: services.lightPicture.description,
        illustration: services.lightPicture.illustration,
      },
      layout: 'service',
    },
    path: 'neues-bild-fuer-karte',
    canActivate: [IsLoggedInGuard],

    loadComponent: () =>
      import('@mkk/lichtbild-upload/component').then(({ LichtbildUploadComponent }) => LichtbildUploadComponent),
    loadChildren: () => import('@mkk/lichtbild-upload/component').then(({ routes }) => routes),
  },
  {
    title: CustomTitleResolver,
    data: {
      titleKey: services.healthCard.title,
      header: {
        headline: services.healthCard.title,
        label: services.healthCard.description,
        illustration: services.healthCard.illustration,
      },
      layout: 'full',
    },
    path: 'egk-bestellen',
    canActivate: [IsLoggedInGuard],

    loadComponent: () => import('@mkk/egk-order/component').then(({ EgkOrderComponent }) => EgkOrderComponent),
    loadChildren: () => import('@mkk/egk-order/component').then(({ routes }) => routes),
  },
  {
    title: CustomTitleResolver,
    data: {
      titleKey: services.sepaEmployer.title,
      header: {
        headline: services.sepaEmployer.title,
        label: services.sepaEmployer.description,
        illustration: services.sepaEmployer.illustration,
      },
      layout: 'full',
    },
    path: 'sepa-firmenkunde',

    loadComponent: () => import('@mkk/sepa/component').then(({ SepaComponent }) => SepaComponent),
    loadChildren: () => import('@mkk/sepa/component').then(({ routes }) => routes),
  },
  {
    title: CustomTitleResolver,
    data: {
      titleKey: services.sepa.title,
      header: {
        headline: services.sepa.title,
        label: services.sepa.description,
        illustration: services.sepa.illustration,
      },
      layout: 'full',
    },
    path: 'sepa',

    loadComponent: () => import('@mkk/sepa/component').then(({ SepaComponent }) => SepaComponent),
    loadChildren: () => import('@mkk/sepa/component').then(({ routes }) => routes),
  },
  {
    title: CustomTitleResolver,
    data: {
      titleKey: services.insuranceRelationship.title,
      header: {
        headline: services.insuranceRelationship.title,
        label: services.insuranceRelationship.description,
        illustration: services.insuranceRelationship.illustration,
      },
      layout: 'full',
    },
    path: 'versicherungsverhaeltnis',
    canActivate: [IsLoggedInGuard],
    loadComponent: () =>
      import('@mkk/versicherungsverhaeltnis/component').then(
        ({ VersicherungsverhaeltnisComponent }) => VersicherungsverhaeltnisComponent,
      ),
    loadChildren: () => import('@mkk/versicherungsverhaeltnis/component').then(({ routes }) => routes),
  },
];
